import { Component, ElementRef, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, Subscription } from 'rxjs';
import { LanguageService } from './i18n';
import { MaintenanceService } from './maintenance';
import { LoaderService } from './store/loader';
import { DesignSwitcherService } from './core/services/design-switch.service';
import { DocumentService } from './v2/shared/services';
import { GtmTrackingService } from './shared/gtm';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [LanguageService, DesignSwitcherService, DocumentService],
})
export class AppComponent implements OnInit, OnDestroy {
    readonly designSwitcherService = inject(DesignSwitcherService);
    private readonly documentService = inject(DocumentService);
    private elementRef = inject(ElementRef);
    private loaderService = inject(LoaderService);
    private languageService = inject(LanguageService);
    private maintenanceService = inject(MaintenanceService);
    private gtmTrackingService = inject(GtmTrackingService);
    private router = inject(Router);

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.loaderService.init();
        this.languageService.init();
        this.maintenanceService.init();
        this.documentService.document = this.elementRef.nativeElement;

        this.runGoogleTagManager();
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private runGoogleTagManager() {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    map((event: NavigationEnd) => this.gtmTrackingService.pageView(event.urlAfterRedirects)),
                )
                .subscribe(),
        );
    }
}
